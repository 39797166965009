import React, { Component } from 'react';
import DetailHeader from './header.component';
import About from './about.component';
import './detail.component.scss';
import Services from './services.component';
import GridList from './destinations.component';
import Contact from './contact.component';
import conditionalClass from 'classnames';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class Detail extends Component {
  render() {
    return (
      <div
        className={conditionalClass({
          detail__content: true,
          'flip-direction': localStorage.getItem('lang') === 'ar',
          'default-direction': localStorage.getItem('lang') === 'en',
        })}
      >
        <DetailHeader />
        <About />
        <GridList />
        <Services />
        <Contact />
      </div>
    );
  }
}

export default Detail;
