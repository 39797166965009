import React, { Component } from 'react';
import Menu from './menu/menu.component';
import Detail from './detail/detail.component';
import './app.component.scss';

import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ar from 'react-intl/locale-data/ar';

import localeData from './assets/locales/data.json';

addLocaleData([...en, ...ar]);

class App extends Component {
  onSelectLanguage = langValue => {
    const language = langValue;
    const messages = localeData[langValue];
    const lang = { language, messages };
    localStorage.setItem('lang', language);
    this.setState(lang);
  };

  constructor() {
    super();
    let language = '';
    if (!!localStorage.getItem('lang')) {
      language = localStorage.getItem('lang');
    } else {
      language = 'ar';
      localStorage.setItem('lang', 'ar');
    }
    this.state = {
      language,
      messages: localeData[language],
    };
  }

  render() {
    return (
      <IntlProvider locale={this.state.language} messages={this.state.messages}>
        <div className="container">
          <div className="sidenav">
            <Menu onSelectLanguage={this.onSelectLanguage} />
          </div>
          <div className="main">
            <Detail />
          </div>
        </div>
      </IntlProvider>
    );
  }
}

export default App;
