import React from 'react';
import './contact.component.scss';
import { icons } from '../assets/icons';
import { FormattedMessage } from 'react-intl';
const setIcon = iconName => {
  return { __html: icons[iconName] };
};

class Contact extends React.Component {
  render() {
    return (
      <div className="contact-container">
        <h1 id="contact-us">
          {' '}
          <FormattedMessage id="contact_header" defaultMessage="Contact Us" />
        </h1>
        <div className="info" style={{ direction: 'ltr' }}>
          <div>
            <svg
              style={{ top: '22px', left: '-82px' }}
              viewBox="0 0 350.23 350.23"
              dangerouslySetInnerHTML={setIcon('mobile')}
            />
          </div>
          <div>
            <a rel="noopener noreferrer" href="tel:+41788051407">
              <span> +41 78 805 14 07</span>
            </a>
            <a rel="noopener noreferrer" href="tel:+41779041050">
              <span> +41 77 904 10 50</span>
            </a>
          </div>
        </div>
        <div className="info">
          <div>
            <svg
              style={{ top: '11px', left: '-95px' }}
              viewBox="0 0 14 14"
              dangerouslySetInnerHTML={setIcon('email')}
            />
          </div>
          <div>
            <a rel="noopener noreferrer" href="mailto:h.info21@yahoo.com">
              <span>h.info21@yahoo.com</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
