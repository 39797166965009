import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import './destinations.component.scss';
import { FormattedMessage, defineMessages } from 'react-intl';
import Lightbox from 'react-image-lightbox';

const images = [
  require(`../assets/photos/photos-list/رحلات مع سائق عربي في سويسرا.jpeg`),
  require(`../assets/photos/photos-list/زيارة البحيرات في إنترلاكن.jpeg`),
  require(`../assets/photos/photos-list/رحلات مائية في لوسيرن.jpeg`),
  require(`../assets/photos/photos-list/زيارة بيرن مع مرشد سياحي عربي في سويسرا.jpeg`),
  require(`../assets/photos/photos-list/زيارة أبرز المعالم الثقافية في جنيف.jpeg`),
  require(`../assets/photos/photos-list/رحلات متعددة الى جبال الالب الشهيرة.jpeg`),
  require(`../assets/photos/photos-list/رحلات تسوق في مختلف المدن السويسرية.jpeg`),
  require(`../assets/photos/photos-list/مهرجان الجاز الأسطوري في مونترو.jpeg`),
];

const ideas = defineMessages({
  message1: {
    id: 'ideas_message1',
    defaultMessage: 'Excursions and hiking near the iconic Matterhorn.',
  },
  message2: {
    id: 'ideas_message2',
    defaultMessage:
      'Canyoning, rafting, lake views in the resort of Interlanken.',
  },

  message3: {
    id: 'ideas_message3',
    defaultMessage:
      'River cruises, museums, concerts are waiting for you in Lucerne.',
  },

  message4: {
    id: 'ideas_message4',
    defaultMessage: `Don't forget to visit Bern, the Swiss capital while you're in Switzerland.`,
  },

  message5: {
    id: 'ideas_message5',
    defaultMessage:
      'Discover the cultural highlights, sightseeing attractions of Geneva and its surrounding region.',
  },
  message6: {
    id: 'ideas_message6',
    defaultMessage: 'Various destinations to the beautiful Alps mountains.',
  },
  message7: {
    id: 'ideas_message7',
    defaultMessage:
      'Restaurants, Cafes and shopping malls are waiting in every city stop.',
  },
  message8: {
    id: 'ideas_message8',
    defaultMessage: `Don't miss the legendary Jazz festival of Montreux in July.`,
  },
});

const titles = [
  'رحلات مع سائق عربي في سويسرا',
  'زيارة البحيرات في إنترلاكن',
  'رحلات مائية في لوسيرن',
  'زيارة بيرن مع مرشد سياحي عربي في سويسرا',
  'زيارة أبرز المعالم الثقافية في جنيف',
  'رحلات متعددة الى جبال الالب الشهيرة',
  'رحلات تسوق في مختلف المدن السويسرية',
  'مهرجان الجاز الأسطوري في مونترو',
];

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 600,
  },
  icon: {
    color: 'white',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background: 'transparent',
    cursor: 'default',
  },
  img: {
    cursor: 'default',
    height: 'auto',
  },
});

const tileData = [
  {
    img: require('../assets/photos/photos-list/رحلات مع سائق عربي في سويسرا.jpeg'),
    title: 'Zermatt',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/زيارة البحيرات في إنترلاكن.jpeg'),
    title: 'Interlaken',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/رحلات مائية في لوسيرن.jpeg'),
    title: 'Lucerne',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/زيارة بيرن مع مرشد سياحي عربي في سويسرا.jpeg'),
    title: 'Bern',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/زيارة أبرز المعالم الثقافية في جنيف.jpeg'),
    title: 'Geneva',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/رحلات متعددة الى جبال الالب الشهيرة.jpeg'),
    title: 'Jungfrau',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/رحلات تسوق في مختلف المدن السويسرية.jpeg'),
    title: 'Zurich',
    author: 'author',
    cols: 4,
  },
  {
    img: require('../assets/photos/photos-list/مهرجان الجاز الأسطوري في مونترو.jpeg'),
    title: 'Montreux',
    author: 'author',
    cols: 4,
  },
];

class ImageGridList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currImg: 0,
    };

    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrev = this.gotoPrev.bind(this);
    this.gotoImg = this.gotoImg.bind(this);
    this.handleClickImg = this.handleClickImg.bind(this);
    this.closeImgsViewer = this.closeImgsViewer.bind(this);
    this.openImgsViewer = this.openImgsViewer.bind(this);
    this.dontOpen = this.dontOpen.bind(this);
  }

  openImgsViewer(index, event) {
    event.preventDefault();
    this.setState({
      currImg: index,
      isOpen: true,
    });
  }
  closeImgsViewer() {
    this.setState({
      currImg: 0,
      isOpen: false,
    });
  }
  gotoPrev() {
    this.setState({
      currImg: (this.state.currImg + images.length - 1) % images.length,
    });
  }
  gotoNext() {
    this.setState({
      currImg: (this.state.currImg + 1) % images.length,
    });
  }
  gotoImg(index) {
    this.setState({
      currImg: index,
    });
  }
  handleClickImg() {
    if (this.state.currImg === images.length - 1) return;
    this.gotoNext();
  }
  dontOpen(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const { classes } = this.props;
    const { currImg, isOpen } = this.state;

    return (
      <div className="grid-container">
        <h1 id="vacation-ideas">
          <FormattedMessage
            id="ideas_header"
            defaultMessage="Our Vacation Ideas"
          />
        </h1>
        <div className={classes.root}>
          <GridList cellHeight={100} className={classes.gridList} cols={8}>
            {Object.keys(ideas).map((key, index) => (
              <GridListTile
                key={tileData[index].img}
                cols={tileData[index].cols || 1}
              >
                <img
                  src={tileData[index].img}
                  alt={titles[index]}
                  className={classes.img}
                  onClick={e => this.openImgsViewer(index, e)}
                />
                <GridListTileBar
                  className={classes.titleBar}
                  onClick={e => this.openImgsViewer(index, e)}
                  actionIcon={
                    <Tooltip
                      onClick={e => this.dontOpen(e)}
                      title={
                        <React.Fragment>
                          <FormattedMessage {...ideas[key]} />
                        </React.Fragment>
                      }
                      disableTouchListener
                    >
                      <IconButton aria-label="Delete" className={classes.icon}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[currImg]}
            nextSrc={images[(currImg + 1) % images.length]}
            prevSrc={images[(currImg + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                currImg: (currImg + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currImg: (currImg + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

ImageGridList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageGridList);
