import React from 'react';
import './services.component.scss';
import { FormattedMessage, defineMessages } from 'react-intl';
import { icons } from '../assets/icons';

const setIcon = iconName => {
  return { __html: icons[iconName] };
};

const iconNames = ['driver', 'waterfall', 'house', 'paperclip'];

const servicesHeading = defineMessages({
  services_heading1: {
    id: 'services_heading1',
    defaultMessage: 'Tour guides',
  },
  services_heading2: {
    id: 'services_heading2',
    defaultMessage: 'Touristic activities',
  },
  services_heading3: {
    id: 'services_heading3',
    defaultMessage: 'Accommodation',
  },
  services_heading4: {
    id: 'services_heading4',
    defaultMessage: 'Trip events',
  },
});

const services = defineMessages({
  services_message1: {
    id: 'services_message1',
    defaultMessage:
      'We provide transportation services - Car rental with and without a driver depending on your choice - The driver is an experienced tour guide from an Arab country.',
  },
  services_message2: {
    id: 'services_message2',

    defaultMessage:
      'Various vacation and touristic activities. Mountains and rural visits - Waterfall, lakes river cruises and much more.',
  },
  services_message3: {
    id: 'services_message3',
    defaultMessage:
      'We prepare your accommodation beforehand. Hotel reservations and Huts rental.',
  },
  services_message4: {
    id: 'services_message4',
    defaultMessage:
      'We take care of organizing your trip events. Documents Translation - Conferences - Honeymoon - Medical and Therapeutic tourism.',
  },
});

class Services extends React.Component {
  render() {
    return (
      <div className="services">
        <h1 id="services">
          <FormattedMessage
            id="services_header"
            defaultMessage="Our Services"
          />
        </h1>
        <div className="services__section">
          {Object.keys(services).map((key, index) => (
            <div key={key}>
              <span dangerouslySetInnerHTML={setIcon(iconNames[index])} />
              <div>
                <h4>
                  <FormattedMessage
                    key={`services_heading${index + 1}`}
                    {...servicesHeading[`services_heading${index + 1}`]}
                  />
                </h4>
              </div>
              <div>
                <FormattedMessage key={key} {...services[key]} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Services;
