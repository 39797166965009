import React, { Component } from 'react';
import './about.component.scss';
import { FormattedMessage, defineMessages } from 'react-intl';

const about = defineMessages({
  message1: {
    id: 'about_message1',
    defaultMessage: 'Who are we?',
  },
  message2: {
    id: 'about_message2',
    defaultMessage: `Go Swissra is a group of specialized Tour guides with many years of experience. We are highly motivated and passionate to provide an entertaining and informative excursion for our customers.`,
  },

  message3: {
    id: 'about_message3',
    defaultMessage: 'Are you planning a trip to Switzerland or Europe?',
  },

  message4: {
    id: 'about_message4',
    defaultMessage: `If yes, then you are in the right place. We will be glad to serve you. All you need to do is send us a message on Whatsapp or call us. We speak 3 languages: English, Arabic and German.`,
  },
});

class About extends Component {
  render() {
    return (
      <div className="about">
        <h1 id="about">
          <FormattedMessage id="about_header" defaultMessage="About" />
        </h1>
        <div className="about__section">
          <div>
            <h4>
              <FormattedMessage {...about['message1']} />
            </h4>
            <div>
              <FormattedMessage {...about['message2']} />
            </div>
          </div>

          <div>
            <FormattedMessage {...about['message3']} />
            <div>
              <FormattedMessage {...about['message4']} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
