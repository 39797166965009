import React, { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { icons } from '../assets/icons';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './menu.component.scss';

const styles = {
  avatar: {
    margin: 10,
    width: '7rem',
    height: '7rem',
  },
};

const headings = defineMessages({
  headings_message1: {
    id: 'headings_message1',
    defaultMessage: 'About',
  },
  headings_message2: {
    id: 'headings_message2',
    defaultMessage: 'Vacation Ideas',
  },
  headings_message3: {
    id: 'headings_message3',
    defaultMessage: 'Services',
  },
  headings_message4: {
    id: 'headings_message4',
    defaultMessage: 'Contact Us',
  },
});

const setIcon = iconName => {
  return { __html: icons[iconName] };
};

const links = {
  facebook: 'https://bit.ly/2tAvwd7',
  instagram: 'https://bit.ly/2TJ8Ohz',
  twitter: 'https://bit.ly/2TN5v9a',
  whatsapp: 'https://wa.me/41788051407',
};

const getLinkIcon = iconName => {
  return links[iconName];
};

const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

class MenuComponent extends Component {
  constructor() {
    super();
    const language = !!localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ar';
    this.state = {
      language,
    };
  }

  changeLang = language => {
    this.setState({ language });
    this.props.onSelectLanguage(language);
  };

  render() {
    return (
      <div className="menu__content">
        <div className="language">
          <li
            className={this.state.language === 'ar' ? 'language--selected' : ''}
            onClick={this.changeLang.bind(this, 'ar')}
          >
            <FormattedMessage id="Menu.arabic" defaultMessage="العربية" />
          </li>
          &nbsp;
          <li
            className={this.state.language === 'en' ? 'language--selected' : ''}
            onClick={this.changeLang.bind(this, 'en')}
          >
            <FormattedMessage id="Menu.english" defaultMessage="EN" />
          </li>
        </div>
        <div className="header">
          <img alt="logo" src={require('../assets/photos/europe.png')} />
        </div>
        <div className="preface">
          <span>Go Swissra</span>
          <div>
            <svg
              style={{ top: '15px' }}
              viewBox="0 0 350.23 350.23"
              dangerouslySetInnerHTML={setIcon('mobile')}
            />
            <div>
              <a rel="noopener noreferrer" href="tel:+41788051407">
                <span> +41 78 805 14 07</span>
              </a>
              <a rel="noopener noreferrer" href="tel:+41779041050">
                <span> +41 77 904 10 50</span>
              </a>
            </div>
          </div>
          <div>
            <svg
              style={{ top: '4px' }}
              viewBox="0 0 14 14"
              dangerouslySetInnerHTML={setIcon('email')}
            />
            <div>
              <a rel="noopener noreferrer" href="mailto:h.info21@yahoo.com">
                <span>h.info21@yahoo.com</span>
              </a>
            </div>
          </div>
        </div>
        <div className="content">
          <ul className="content__menu-items">
            {Object.keys(headings).map(key => (
              <li key={key}>
                <a
                  href={`#${headings[key].defaultMessage
                    .toLowerCase()
                    .replace(' ', '-')}`}
                >
                  <FormattedMessage key={key} {...headings[key]} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer">
          {['whatsapp', 'facebook', 'instagram', 'twitter'].map(iconName => (
            <a
              key={iconName}
              target="_blank"
              rel="noopener noreferrer"
              href={getLinkIcon(iconName)}
              dangerouslySetInnerHTML={setIcon(iconName)}
            />
          ))}
        </div>
        <div className="postscript">
          <p> &copy; {getCurrentYear()}.&nbsp; All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}

// تأمين النقل مع المرشد السياحي
// زيارة مواقع سياحية مختلفة
// تأمين الاقامة
// تجهيز معاملات رحلتك
// ارشاد سياحي للأخوة العرب والأجانب

MenuComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuComponent);
