import React, { Component } from 'react';
import './header.component.scss';
import ImageGallery from 'react-image-gallery';
import Lightbox from 'react-image-lightbox';

const image = Object.freeze([
  'سائق عربي في سويسرا',
  'إستئجار سيارة في سويسرا',
  'رحلات إلى زيوريخ',
  'رحلات إلى جنيف',
  'رحلات إلى مونترو',
  'اكواخ ريفيه في سويسرا',
  'رحلات إلى زلامسي',
  'مناطق ريفية في أوروبا',
  'مناظر سويسرا الخلابة',
  'افضل سكن في انترلاكن للعائلات',
  'افضل شقق في انترلاكن',
  'رحلات الى أوروبا',
  'رحلات متعددة الى جبال الالب',
  'رحلات إلى انترلاكن',
  'رحلات مائية في الأنهار والبحيرات',
  'مرشد سياحي في سويسرا',
  'جبال ومناطق ريفية في سويسرا',
  'رحلات إلى منطقة الماترهورن',
  'زيارة المدن والتسوق',
  'رحلات الى سويسرا',
  'فنادق جبال الالب في سويسرا',
  'اكواخ ريفيه في انترلاكن',
  'حجوزات فنادق وتأجير أكواخ',
  'تأجير سيارة مع سائق عربي في سويسرا',
  'أفضل شركة سياحية في سويسرا',
]);

const images = [
  require('../assets/photos/landscapes/سائق عربي في سويسرا.jpg'),
  require('../assets/photos/landscapes/إستئجار سيارة في سويسرا.jpg'),
  require('../assets/photos/landscapes/رحلات إلى زيوريخ.jpg'),
  require('../assets/photos/landscapes/رحلات إلى جنيف.jpg'),
  require('../assets/photos/landscapes/رحلات إلى مونترو.jpg'),
  require('../assets/photos/landscapes/اكواخ ريفيه في سويسرا.jpg'),
  require('../assets/photos/landscapes/رحلات إلى زلامسي.jpg'),
  require('../assets/photos/landscapes/مناطق ريفية في أوروبا.jpg'),
  require('../assets/photos/landscapes/مناظر سويسرا الخلابة.jpg'),
  require('../assets/photos/landscapes/افضل سكن في انترلاكن للعائلات.jpg'),
  require('../assets/photos/landscapes/افضل شقق في انترلاكن.jpg'),
  require('../assets/photos/landscapes/رحلات الى أوروبا.jpg'),
  require('../assets/photos/landscapes/رحلات متعددة الى جبال الالب.jpg'),
  require('../assets/photos/landscapes/رحلات إلى انترلاكن.jpg'),
  require('../assets/photos/landscapes/رحلات مائية في الأنهار والبحيرات.jpg'),
  require('../assets/photos/landscapes/مرشد سياحي في سويسرا.jpg'),
  require('../assets/photos/landscapes/رحلات إلى منطقة الماترهورن.jpg'),
  require('../assets/photos/landscapes/زيارة المدن والتسوق.jpg'),
  require('../assets/photos/landscapes/رحلات الى سويسرا.jpg'),
  require('../assets/photos/landscapes/فنادق جبال الالب في سويسرا.jpg'),
  require('../assets/photos/landscapes/اكواخ ريفيه في انترلاكن.jpg'),
  require('../assets/photos/landscapes/حجوزات فنادق وتأجير أكواخ.jpg'),
  require('../assets/photos/landscapes/تأجير سيارة مع سائق عربي في سويسرا.jpg'),
  require('../assets/photos/landscapes/أفضل شركة سياحية في سويسرا.jpg'),
];

const galleryImages = [...images].map((img, index) => {
  return { original: img, originalAlt: image[index] };
});

class DetailHeader extends Component {
  constructor() {
    super();
    this.state = {
      viewerIsOpen: false,
      currImg: 0,
      spinIndex: 0,
    };

    this.updateSpinningImgIdx = this.updateSpinningImgIdx.bind(this);
    this.openImgsViewer = this.openImgsViewer.bind(this);
  }

  openImgsViewer(index, event) {
    event.preventDefault();
    this.setState({
      currImg: index,
      isOpen: true,
    });
  }

  updateSpinningImgIdx(index) {
    this.setState({
      spinIndex: index,
    });
  }

  render() {
    const { currImg, isOpen } = this.state;
    return (
      <div>
        <ImageGallery
          items={galleryImages}
          slideDuration={1000}
          slideInterval={3000}
          showBullets={false}
          infinite={true}
          isRTL={true}
          autoPlay={true}
          showNav={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          onSlide={this.updateSpinningImgIdx}
          onClick={e => this.openImgsViewer(this.state.spinIndex, e)}
        />
        {isOpen && (
          <Lightbox
            mainSrc={images[currImg]}
            nextSrc={images[(currImg + 1) % images.length]}
            prevSrc={images[(currImg + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                currImg: (currImg + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currImg: (currImg + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default DetailHeader;
